* {
  outline: none !important;
  font-family: futura !important

}

#mainDashboardDiv {
  margin-top: 105px
}

.smallTextFinePrint {
  font-size: 13px !important;
  color: gray !important;
}

.TermsOfUse h1 {
  font-size: 40px;
  margin: 30px 0px;
  font-weight: bold;
  font-family: futura;
}

.TermsOfUse {
  text-align: justify;
  margin-top: 105px;
}

.TermsOfUse h2 {
  font-size: 30px;
  margin: 20px 0px;
  font-family: futura;
  font-weight: bold;
}

.NavBarMenu .MuiPaper-root {
  background-color: #393e41 !important;
  color: white !important;
  position: absolute;
  top: 80px !important;
  right: 20px !important;
  width: 175px;
}

.NavBarMenu .MuiListItem-root {
  font-family: futura !important;
  font-weight: 400;
}
.NavBarMenu .MuiListItem-root:hover {
  /* font-family: futura !important; */
  font-weight: 600;
}

body, .MuiTypography-body2, .MuiTypography-body1 {
  font-family: futura !important;
}

.MuiChip-deleteIcon {
  color: white !important
}

.MuiChip-root {
  font-size: 18px !important;
  background-color: #3f51b5 !important;
  color: white !important;
}

[class*="MUIDataTableSearch-clearIcon-"] {
  display: none !important;
}

.myCards {
  height: 250px;
  max-width: 350px;
  border: 1px solid gray;
  background-color: rgba(211, 211, 211, 0.21) !important;

}

.IssueListItem {
  border-bottom: 1px solid gray !important;
  background-color: #d3d3d336 !important;
  padding: 8px 12px !important
}
.IssueListSelectedItem {
  border-bottom: 1px solid gray !important;
  background-color: lightgray !important;
  padding: 8px 12px !important
}


#mainDashboardDiv {
  margin: 105px 40px 20px;
}

.MuiAppBar-root {
  position: fixed !important;
  top: 0 !important;
  right: 0;
  left: auto;
}

#navBarPadding {
  margin-top: 180px;
}

@media screen and (max-width: 600px) {
  #DrawingScreen {
    top: 60px !important
  }
  .NavBarMenu .MuiPaper-root {
    top: 60px !important
  }
  .TermsOfUse {
    text-align: justify;
    margin-top: -15px;
  }
  .MuiAppBar-root {
    height: 60px !important;
    padding: 10px 0px !important;
    position: static !important;
  }
  #mainDashboardDiv {
    margin: 10px 20px 10px;
  }
  #navBarPadding {
    margin-top: 60px;
  }
}

#svg-pan-zoom-controls {
  transform: translate(10px, 10px) scale(.75);
}

#svg_office_layout {
  background-color: rgba(211,211,211,0.21)
}

/* #QIDlogo {
  width: 75px;
  height: 75px;
  margin-right: 8px;
} */

#logo {
  width: 160px;
  height: 80px;
}

@media screen and (max-width: 600px) {
  #logo {
    width: 110px;
    height: 55px;
    margin-bottom: 8px;
  }
  /* #QIDlogo {
    width: 50px;
    height: 50px;
  } */
  .logoSubtitle {
    font-size: 12px !important
  }

  #logoContainer {
    margin-bottom: 6px;
  }
}


.MuiCardContent-root .MuiTypography-body1 {
  font-family: futura !important;
  font-size: 17px !important;
  font-weight: 600 !important;
}

.MuiCardContent-root .MuiTypography-body2 {
  font-family: futura !important;
  font-size: 17px !important;
  font-weight: 500 !important;
}

.MuiBreadcrumbs-ol {
  color: #3e51b5 !important;
  /* font-size: 15px !important; */
  /* color: #04499e !important; */
}

.MuiBreadcrumbs-li .MuiTypography-root, .infoText {
  font-size: 20px !important;
}

.MuiBreadcrumbs-li a.MuiTypography-root {
  color: #04499e !important;
}

.MuiBreadcrumbs-li .MuiTypography-root svg {
  width: 26px !important;
  height: 26px !important;
}

@media screen and (max-width: 600px) {
  .MuiBreadcrumbs-li .MuiTypography-root, .infoText {
    font-size: 15px !important;
  }
  
  .MuiBreadcrumbs-li .MuiTypography-root svg {
    width: 20px !important;
    height: 20px !important;
  }
}


.Issuelabel, .Issuelabel .MuiTypography-root, .MuiTableCell-head {
  font-family: futura !important;
  font-weight: 500 !important;
  font-size: 18px !important;
}

.MuiFormLabel-root.MuiInputLabel-root {
  font-family: futura !important;
  font-weight: 500 !important;
  font-size: 18px !important;
  color: black
}

@media screen and (max-width: 600px) {
  .Issuelabel, .Issuelabel .MuiTypography-root, .MuiTableCell-head {
    font-size: 15px !important;
  }
  .MuiFormLabel-root.MuiInputLabel-root {
    font-size: 15px !important;
  }
}

.MuiTypography-h6 {
  font-family: futura !important;
  font-weight: 600 !important;
  font-size: calc(6px + 1.8vw) !important;
}

.icon {
  font-size: 75px !important;
}
@media screen and (max-width: 960px) {
  .icon {
    font-size: 50px !important;
  }
}


audio {
  height: 25px;
  margin-top: 5px;
  max-width: 100%;
}


/*Issue List Scroll Bar Text*/
.MuiTypography-body2 {
  font-size: 10px !important;
}







/* Reports CSS*/
.recharts-legend-item-text {
  font-size: 20px;
}

.ReportFilterLabel {
  font-family: futura !important;
  font-weight: 500 !important;
  font-size: calc(8px + 1.5vw) !important;
}

#ReportsPage .MuiInputBase-root {
  font-size: calc(6px + 1.2vw) !important
}
.ReportTableText p, .ReportTableText span {
  font-size: calc(6px + 1.1vw) !important
}







/*SETUP*/

.MuiStepLabel-label {
  font-size: 16px !important
}

@media screen and (max-width: 600px) {
  .MuiStepper-alternativeLabel {
    padding: 24px 0px !important;
  }
}






.MuiButton-containedSecondary {
  background-color: #ecb93e !important;
  color: #393e41 !important
}

@media (hover: hover) {
  .MuiButton-containedSecondary:hover {
    background-color: #393e41 !important;
    color: #ecb93e !important
  }
  .MuiButton-containedPrimary:hover {
    background-color: #d3d0cb !important;
    color: #04499e !important;
  }
}

.MuiButton-containedSecondary:active {
  background-color: #393e41 !important;
  color: #ecb93e !important
}
.MuiButton-containedPrimary:active {
  background-color: #d3d0cb !important;
  color: #04499e !important;
}

.MuiButton-containedPrimary {
  background-color: #04499e !important;
  color: #d3d0cb !important;
}



.MuiSvgIcon-colorPrimary {
  color: #04499e !important
}


.MuiButton-contained {
  min-width: 112px !important;
  font-family: futura !important;
  height: 40px !important;
  font-weight: normal !important;
   
}

.imageBox {
  min-width: 50px !important;
  width: 100%;
}

.MuiInput-root, .MuiInputBase-root {
  line-height: initial !important;
}